import React from 'react'
import { graphql } from 'gatsby'
import Layout from './layout'
import useCourses from '../hooks/useCourses'
import useCourseTemplate from '../hooks/useCourseTemplate'
import {
  CustomSections,
  SimpleBanner,
  TextBlock,
  ContactForm,
  CardsSection,
  CourseDetails,
  Notice,
  Seo,
  AreasSection
} from '../components'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from 'react-share'
import PropTypes from 'prop-types'

const Course = ({ data }) => {
  const course = data.allStrapiFjsCourses.nodes[0]

  const dataCourses = useCourses()
  const fjsCourses = dataCourses?.allStrapiFjsCourses?.nodes

  const dataCourseTemplate = useCourseTemplate()
  const { defaultCourseImg, certificate, relatedCourses, form, info } =
    dataCourseTemplate?.strapiCourseFjsTemplate

  const areaCourses = fjsCourses
    .filter((courseDetails) =>
      courseDetails.interest_areas.find(
        (areaInt) =>
          course.interest_areas[0].slug === areaInt.slug &&
          course.slug !== courseDetails.slug
      )
    )
    .splice(0, 4)

  let shareUrl = ''
  if (typeof window !== 'undefined') {
    shareUrl = window.location.href
  }

  // Sort date cursos relacionados
  function compareDates(dateA, dateB) {
    return (
      new Date(dateB.dateStart) -
      new Date(dateA.dateStart)
    )
  }
  areaCourses.sort(compareDates)

  return (
    <Layout>
      <Seo title={course.name} />
      <AreasSection />
      <div className="course-container">
        {(course.image || defaultCourseImg) && (
          <SimpleBanner
            image={course.image}
            text={course.name}
            link={course.link || course.link_inscripcion}
            linkContacto={course.linkContacto}
            category={course.training_type.title}
            date={course.dateStart}
            dateEndInscr={course.dateEndInscr}
            imageDefault={defaultCourseImg}
          />
        )}
        <div className="py-5">
          {/* Descripción de cursos */}
          {course.features && (
            <CourseDetails date={course.dateStart} features={course.features} link={course.link || course.link_inscripcion}
            linkContacto={course.linkContacto} dateEndInscr={course.dateEndInscr}/>
          )}

          {/* Seción zona Dinámica (Video o Pdf) */}
          {course.body && <CustomSections sections={course.body} />}

          {/* Sección formulario */}
          {form && (
            <ContactForm
              idForm={form.form.id}
              title={form.title}
              origin={course.slug}
            />
          )}

          {/* Sección Compartir en Redes */}
          <div className="d-flex flex-column justify-content-center align-items-center justify-content-start mb-5 primary-grey-font">
            <h5 className="p-4 p-lg-2">
              Compartir este curso en tus redes sociales
            </h5>
            <div className="mt-2">
              <FacebookShareButton
                url={shareUrl}
                quote={course.name}
                className="mr-1"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={course.name}
                className="mr-1"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl} className="mr-1">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          </div>

          {/* Sección Certificado */}
          {certificate && <Notice content={certificate.content} />}

          {/* Sección cursos relacionados */}
          {relatedCourses && areaCourses.length > 0 && (
            <CardsSection
              title={relatedCourses.title}
              related={true}
              courses={areaCourses}
              id="cursos-relacionados"
            />
          )}

          {/* Sección Informes */}
          {info && <TextBlock content={info} title={'Informes'} />}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allStrapiFjsCourses(
      filter: { slug: { eq: $slug } }
      sort: { fields: dateStart }
    ) {
      nodes {
        body
        link
        link_inscripcion
        linkContacto
        anio
        code
        slug
        training_type {
          title
          slug
        }
        strapiId
        name
        interest_areas {
          slug
          title
        }
        id
        dateFinish(formatString: "DD MMM YYYY", locale: "ES-AR")
        dateFinishInscr(formatString: "DD MMM YYYY", locale: "ES-AR")
        dateStart(formatString: "DD MMM YYYY", locale: "ES-AR")
        dateStartInscr(formatString: "DD MMM YYYY", locale: "ES-AR")
        dateEndInscr: dateFinishInscr
        features {
          teachers {
            id
            role
            name
            teacherDetails {
              description
              image {
                url
              }
            }
          }
          schedule {
            id
            duration
            date(formatString: "DD MMM YYYY", locale: "ES-AR")
          }
          priceLists {
            content
            price {
              id
              description
              price
              startDate
            }
            specialPrice {
              expiration
              price {
                description
                price
                startDate
              }
            }
            PageLists {
              name
              fjs_page {
                slug
              }
            }
          }
          moreInformation {
            place
            requirements
          }
          description {
            addressedTo
            description
          }
          contents {
            contents
          }
          objectives {
            objectives
          }
          cardAgreements {
            agreement {
              id
              bank
              card
              fee
              interest
              validity
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
export default Course

Course.propTypes = {
  data: PropTypes.array
}
